<template lang="html">
	<div class="container-fluid contain">
		<div class="">
			<img src="@/assets/images/icon_arrow_left.png" style="width: 18px; margin-left: 10px;" @click="$router.go(-1)"/>
			<div class="login-title" style="margin-top: 22px; margin-bottom: 10px; font-size: 35px; text-align: left; margin-left: 10px;">ADD PAYMENT METHOD</div>
			<div style="margin: 10px; margin-top: 30px;">
				<v-braintree
					:authorization="get(state, 'token', '')"
					@success="onSuccess"
					@error="onError"
					v-if="state.token"
				></v-braintree>
			</div>
		</div>
	</div>
</template>

<script>
  import {reactive, toRefs} from '@vue/composition-api';
  import {get} from 'lodash';
  import $ from 'jquery'
  export default {
    props: ['updatePayment'],
    setup(props, {root}) {
      const data = reactive({
				get
      });
      const onSuccess = async (payload) => {
        $('.btn-primary').text('');
        $('.btn-primary').addClass('d-flex justify-content-center align-items-center');
        $('.btn-primary').append('<div class="loader" v-if="state.isLoading"></div>');
        try {
          const param = {
            type: 'payment',
            cardType: payload?.details?.cardType,
            last4: payload?.details?.lastFour,
            token: payload?.nonce,
            isValid: true,
            isDefault: true
          };
          await root.actions.user.onAddPayment(param);
          root.$router.go(-1);
        } catch (e) {
          console.log(e);
        } finally {
          $('.btn-primary').text('Add');
          $('.loader').remove()
        }
      };

      const onError = (error) => {
        console.log(error)
      };
      const getBraintreeToken = async () => {
        const loader = root.$loading.show();
        try {
          if (!root.state.currentUser?.id) {
            const { token, userId } = await root.actions.getStoredAuthToken();
            await root.actions.loginWithTokenForBraintree({ token, userId });
          } else if (root.state.currentUser?.id && !root.state.token) {
            await root.actions.user.onCreateBraintreeToken();
          }
        } catch (e) {
					console.log(e)
        } finally {
					loader.hide();
        }
			};
      return {
        onSuccess,
        onError,
        getBraintreeToken,
        ...toRefs(data)
      }
    },
		mounted() {
      this.getBraintreeToken();
			$('.braintree-toggle').click();
			$('.btn-primary').text('Add');
    }
  }
</script>

<style lang="css" scoped>

	select {
		border: 0;
		outline: 0;
	}

</style>
